.container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  position: relative;
  overflow: hidden;
}

.container .content {
  max-width: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: center center;
}

.container .content img {
  width: 90%;
}

.container .content p {
  color: #fff;
  text-align: center;
  font-size: 21px;
  line-height: 34px;
  margin-top: 10px;
  margin-bottom: 0;
}

.container .content .count{
  font-weight: 800;
  font-size: 24px;
  color: #fff;
  margin: 10px 0 20px 0;
}

.container .content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1000px) {
  .container .content {
    transform: translate(-50%, -40%) scale(0.9);
  }
}

@media (max-width: 800px) {
  .container .content {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

.container .powered {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 160px;
}


.disconnectBtn{
  position: absolute;
  top: 30px;
  right: 30px;
}

.disconnectBtn ul{
  background: linear-gradient(308deg, #694298 0%, #3c2e4f 100%) !important;
  overflow: hidden;
  position: relative;
}

.disconnectBtn ul::after{
  content: "";
  position: absolute;
  top: 30px;
  bottom: 30px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  transform: rotate(-45deg) scale(1.5);
  color: #fff;
}

.disconnectBtn ul li{
  background-color: #fed133 !important;
  color: #000;
}

.disconnectBtn ul li:hover {
  background-color: #ffe638;
}
.disconnectBtn button{
  border-radius: 200px !important;
  transform: scale(0.8);
}


.container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}


.modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(308deg, #694298 0%, #3c2e4f 100%) !important;
    padding: 24px;
    border-radius: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 380px;
    overflow: hidden;
}

.modal::after {
    content: "";
    position: absolute;
    top: -100px;
    bottom: -100px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.05);
    z-index: -1;
    transform: rotate(-45deg) scale(1);
    color: #fff;
}

.loadingIcon{
    padding: 20px 20px 0 20px;
    fill: #f0c118;
    color: #f0c118;
}

.errorIcon{
    width: 50px;
    height: 50px;
}


.errorIcon svg{
    width: 50px;
    height: 50px;
    fill: #fff;
}
.image{
    width: 300px;
    height: 300px;
    border-radius: 16px;
    overflow: hidden;
}

.image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header{
    margin-bottom: 20px;
}

.closeBtn{
    width: 20px;
    height: 20px;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.1s;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 12px;
}

.closeBtn svg{
    width: 18px;
    height: 18px;
}

.closeBtn:hover{
    background: rgba(255, 255, 255, 0.1);
}

.content.error{
    color: #fff;
}

.content{
    margin-top: 20px;
}

.btns{
    display: flex;
    gap: 16px;
    flex-direction: row-reverse;
    padding-top: 20px;
}

.btns a{
    color: #f0c118;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    transition: all 0.1s;
}

.btns a:hover{
    background: rgba(240, 193, 24, 0.15);
}

.btns a p{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.btns a svg{
    width: 20px;
    height: 20px;
}

.btns button{
    background: #f0c118;
    color: #000;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    padding: 4px 12px;
    cursor: pointer;
    transition: all 0.1s;
}

.btns button:hover{
    background: #ffe638;
}
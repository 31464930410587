@keyframes cloudPink1Animation {
  0% {
    transform: translate3D(0, 0, 0);
  }

  50% {
    transform: translate3D(700px, 0, 0);
  }

  100% {
    transform: translate3D(0, 0, 0);
  }
}

@keyframes cloudPink2Animation {
  0% {
    transform: translate3D(600px, 0, 0);
  }

  50% {
    transform: translate3D(0, 0, 0);
  }

  100% {
    transform: translate3D(600px 0, 0);
  }
}

@keyframes cloudPink2Animation {
  0% {
    transform: translate3D(400px, 0, 0);
  }

  50% {
    transform: translate3D(600px, 0, 0);
  }

  100% {
    transform: translate3D(400px, 0, 0);
  }
}

.cloudPink1 {
  position: absolute;
  left: 10%;
  top: 32%;
  animation: cloudPink1Animation 80s infinite linear;
}

.cloudPink3 {
  position: absolute;
  top: 28%;
  animation: cloudPink2Animation 60s infinite linear;
}

.cloudPink2 {
  position: absolute;
  top: 43%;
  animation: cloudPink2Animation 100s infinite linear;
}

.duckImage {
  width: 450px;
  position: absolute;
  bottom: -80px;
  left: 150px;
}

@media (max-width: 1519px) {
  .duckImage {
    width: 400px;
    bottom: -100px;
    left: 0;
  }
}

@media (max-width: 1200px) {
  .duckImage {
    width: 400px;
    bottom: -100px;
    left: -100px;
  }
}

@media (max-width: 800px) {
  .duckImage {
    width: 350px;
    bottom: -100px;
    left: -100px;
  }
}

@media (max-width: 550px) {
  .duckImage {
    width: 300px;
    bottom: -100px;
    left: -100px;
  }
}

.duckImage img {
  width: 100%;
}

@import url("fonts.css");

* {
  font-family: "Poppins";
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.wallet-adapter-modal-wrapper {
  background: linear-gradient(308deg, #694298 0%, #3c2e4f 100%) !important;
  overflow: hidden;
  box-shadow: 0px 10px 30px 0px rgba(14, 84, 159, 0.42) !important;
  border-radius: 8px !important;
}

.wallet-adapter-modal-wrapper::after {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  transform: rotate(-45deg) scale(1.3);
}

.wallet-adapter-button {
  background-color: #fed133 !important;
  color: #3c2e4f !important;
}

.wallet-adapter-button:hover {
  background: #f0c118 !important;
}

.wallet-adapter-modal-button-close svg {
  fill: rgba(255, 255, 255, 0.5) !important;
}

.wallet-adapter-modal-button-close:hover > svg {
  fill: rgba(255, 255, 255, 1) !important;
}


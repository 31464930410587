.mintBtn {
  background-color: #fed133 !important;
  border-radius: 200px !important;
  color: #000 !important;
  font-family: "POPPINS", serif !important;
  padding: 15px 90px !important;
  font-size: 20px !important;
  border: 0 !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  height: unset !important;
  line-height: unset !important;
}

.mintBtn:hover {
  background-color: #ffe638;
}

.mintBtn > i {
  display: none;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/poppins-v15-latin-100.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-100.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/poppins-v15-latin-200.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-200.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/poppins-v15-latin-300.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-300.woff") format("woff");
  font-display: swap;
}

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/poppins-v15-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/poppins-v15-latin-500.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-500.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/poppins-v15-latin-600.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-600.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/poppins-v15-latin-700.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-700.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/poppins-v15-latin-800.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-800.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/poppins-v15-latin-900.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-900.woff") format("woff");
  font-display: swap;
}
